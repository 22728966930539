import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './QuizPage.css';

function QuizPage() {
  const { category } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [showTip, setShowTip] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerChecking, setIsAnswerChecking] = useState(false);

  const getCategoryTitle = () => {
    switch(category) {
      case 'deyimler':
        return 'Türkçe Deyimler';
      case 'atasozleri':
        return 'Türkçe Atasözleri';
      case 'esanlamli':
        return 'Eş Anlamlı Kelimeler';
      case 'zitanlamli':
        return 'Zıt Anlamlı Kelimeler';
      case 'ingtur6-1':
        return '6. Sınıf İngilizce - 1. Ünite (Life)';
      case 'ingtur6-2':
        return '6. Sınıf İngilizce - 2. Ünite (Yummy Breakfast)';
      case 'ingtur6-3':
        return '6. Sınıf İngilizce - 3. Ünite (Downtown)';
      case 'ingtur6-4':
        return '6. Sınıf İngilizce - 4. Ünite (Weather and Emotions)';
      default:
        return '';
    }
  };

  const getCategoryDescription = () => {
    switch(category) {
      case 'deyimler':
        return 'Günlük hayatta sıkça kullanılan deyimleri öğrenin ve kendinizi daha iyi ifade edin.';
      case 'atasozleri':
        return 'Kültürel mirasımızın önemli parçası olan atasözlerini öğrenin ve bilgeliğinizi artırın.';
      case 'esanlamli':
        return 'Kelime hazinenizi zenginleştirin ve kendinizi daha etkili ifade edin.';
      case 'zitanlamli':
        return 'Zıt anlamlı kelimeleri öğrenerek dilinizi daha zengin ve etkili kullanın.';
      case 'ingtur6-1':
        return 'Günlük hayat ve aktivitelerle ilgili İngilizce kelime ve ifadeleri öğrenin.';
      case 'ingtur6-2':
        return 'Yiyecek, içecek ve kahvaltı ile ilgili İngilizce kelime ve ifadeleri öğrenin.';
      case 'ingtur6-3':
        return 'Şehir hayatı ve günlük aktivitelerle ilgili İngilizce kelime ve ifadeleri öğrenin.';
      case 'ingtur6-4':
        return 'Hava durumu, duygular ve kıyafetlerle ilgili İngilizce kelime ve ifadeleri öğrenin.';
      default:
        return '';
    }
  };

  const getLearningTip = () => {
    switch(category) {
      case 'deyimler':
        return 'İpucu: Deyimleri cümle içinde kullanarak pratik yapın. Günlük konuşmalarınızda deyimlere yer vermeye çalışın.';
      case 'atasozleri':
        return 'İpucu: Atasözlerinin hangi durumlarda kullanıldığını düşünün ve günlük hayattan örnekler bulmaya çalışın.';
      case 'esanlamli':
        return 'İpucu: Eş anlamlı kelimeleri farklı cümlelerde kullanarak anlamlarını pekiştirin.';
      case 'zitanlamli':
        return 'İpucu: Zıt anlamlı kelimeleri karşılaştırmalı cümlelerde kullanmaya çalışın.';
      default:
        return 'İpucu: Kelimeleri günlük hayatta kullanmaya çalışın ve düzenli tekrar yapın.';
    }
  };

  // Fisher-Yates shuffle algoritması
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/data/${category}.json`);
        if (!response.ok) {
          throw new Error('Sorular yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        
        let selectedQuestions;
        if (data.questions) {
          const indices = shuffleArray([...Array(data.questions.length).keys()]);
          selectedQuestions = indices.slice(0, 10).map(i => {
            const question = { ...data.questions[i] };
            question.options = shuffleArray(question.options);
            return question;
          });
        } else {
          throw new Error('Geçersiz veri formatı');
        }

        setQuestions(selectedQuestions);
        setAnswers(new Array(selectedQuestions.length).fill(null));
        setLoading(false);
      } catch (error) {
        console.error('Sorular yüklenirken hata oluştu:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    loadQuestions();
  }, [category]);

  const handleAnswerClick = (answer) => {
    if (isAnswerChecking) return; // Cevap kontrol edilirken yeni cevap seçilmesini engelle

    setSelectedAnswer(answer);
    setIsAnswerChecking(true);
    
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = answer;
    setAnswers(newAnswers);

    if (answer === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    // 2 saniye sonra bir sonraki soruya geç
    setTimeout(() => {
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
        setSelectedAnswer(null);
        setIsAnswerChecking(false);
        setShowTip(false);
      } else {
        setShowScore(true);
      }
    }, 2000);
  };

  const handleRetry = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setAnswers(new Array(questions.length).fill(null));
    setShowTip(false);
    setSelectedAnswer(null);
    setIsAnswerChecking(false);
    setQuestions(shuffleArray(questions.map(q => ({
      ...q,
      options: shuffleArray(q.options)
    }))));
  };

  const getAnswerButtonClass = (option) => {
    if (!isAnswerChecking) {
      return 'answer-button';
    }
    
    if (option === questions[currentQuestion].correctAnswer) {
      return 'answer-button correct';
    }
    
    if (selectedAnswer === option) {
      return 'answer-button incorrect';
    }
    
    return 'answer-button disabled';
  };

  const renderAnswerReview = () => {
    return (
      <div className="answer-review">
        <h3>Soru Değerlendirmesi</h3>
        {questions.map((question, index) => {
          const isCorrect = answers[index] === question.correctAnswer;
          return (
            <div key={index} className={`review-item ${isCorrect ? 'correct' : 'incorrect'}`}>
              <p className="review-question">{index + 1}. {question.question}</p>
              <p className="review-answer">
                Sizin cevabınız: <span className={isCorrect ? 'correct-text' : 'incorrect-text'}>
                  {answers[index]}
                </span>
              </p>
              {!isCorrect && (
                <p className="correct-answer">
                  Doğru cevap: <span className="correct-text">{question.correctAnswer}</span>
                </p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="quiz-container">
        <div className="loading">Sorular yükleniyor...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="quiz-container">
        <div className="error">
          <p>{error}</p>
          <button onClick={() => navigate('/')}>Ana Sayfaya Dön</button>
        </div>
      </div>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="quiz-container">
        <div className="error">
          <p>Bu kategoride henüz soru bulunmamaktadır.</p>
          <button onClick={() => navigate('/')}>Ana Sayfaya Dön</button>
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <h1>{getCategoryTitle()}</h1>
        <p className="quiz-description">{getCategoryDescription()}</p>
      </div>
      
      {showScore ? (
        <div className="score-section">
          <h2>Test Tamamlandı!</h2>
          <p>Toplam {questions.length} sorudan {score} tanesini doğru bildiniz.</p>
          <div className="performance-feedback">
            {score === questions.length && <p className="perfect-score">Mükemmel! Tüm soruları doğru yanıtladınız. 🎉</p>}
            {score >= questions.length * 0.7 && score < questions.length && <p className="good-score">Çok iyi! Biraz daha pratik ile mükemmel olacaksınız. 👏</p>}
            {score < questions.length * 0.7 && <p className="improve-score">Daha fazla pratik yaparak kendinizi geliştirebilirsiniz. 💪</p>}
          </div>
          {renderAnswerReview()}
          <div className="button-group">
            <button onClick={handleRetry}>Tekrar Dene</button>
            <button onClick={() => navigate('/')}>Ana Sayfaya Dön</button>
          </div>
        </div>
      ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Soru {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div className="question-text">
              {questions[currentQuestion].question}
            </div>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswerClick(option)}
                className={getAnswerButtonClass(option)}
                disabled={isAnswerChecking}
              >
                {option}
                {isAnswerChecking && option === questions[currentQuestion].correctAnswer && 
                  <span className="correct-indicator"> ✓ Doğru Cevap</span>
                }
                {isAnswerChecking && selectedAnswer === option && option !== questions[currentQuestion].correctAnswer && 
                  <span className="incorrect-indicator"> ✗ Yanlış Cevap</span>
                }
              </button>
            ))}
          </div>
          <div className="learning-help">
            <button 
              className="tip-button" 
              onClick={() => setShowTip(!showTip)}
            >
              {showTip ? 'İpucunu Gizle' : 'İpucu Göster'}
            </button>
            {showTip && (
              <div className="tip-content">
                {getLearningTip()}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default QuizPage; 