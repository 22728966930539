import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    if (category === 'english') {
      navigate('/english');
    } else {
      navigate(`/quiz/${category}`);
    }
  };

  return (
    <div className="home-container">
      <div className="home-header">
        <h1>Kelime Deposu</h1>
        <p>Türkçe ve İngilizce dil öğrenme platformuna hoş geldiniz!</p>
        <div className="platform-description">
          <h2>Platformumuz Hakkında</h2>
          <p>Kelime Deposu, Türkçe ve İngilizce dillerinde kendinizi geliştirmeniz için tasarlanmış kapsamlı bir eğitim platformudur. 
          Deyimler, atasözleri, eş-zıt anlamlı kelimeler ve İngilizce kelime öğrenimi için interaktif testler sunuyoruz.</p>
        </div>
      </div>
      <div className="category-grid">
        <div className="category-card" onClick={() => handleCategoryClick('deyimler')}>
          <h2>Deyimler</h2>
          <p>Türkçe deyimleri öğren ve test et</p>
          <div className="category-info">
            <p>• 50+ yaygın kullanılan deyim</p>
            <p>• Detaylı açıklamalar</p>
            <p>• Örnek cümleler</p>
          </div>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('atasozleri')}>
          <h2>Atasözleri</h2>
          <p>Türkçe atasözlerini öğren ve test et</p>
          <div className="category-info">
            <p>• Kültürel miras niteliğinde atasözleri</p>
            <p>• Anlamları ve kullanımları</p>
            <p>• Günlük hayattan örnekler</p>
          </div>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('esanlamli')}>
          <h2>Eş Anlamlı Kelimeler</h2>
          <p>Eş anlamlı kelimeleri öğren ve test et</p>
          <div className="category-info">
            <p>• Zengin kelime hazinesi</p>
            <p>• Yaygın kullanılan eş anlamlılar</p>
            <p>• Pratik alıştırmalar</p>
          </div>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('zitanlamli')}>
          <h2>Zıt Anlamlı Kelimeler</h2>
          <p>Zıt anlamlı kelimeleri öğren ve test et</p>
          <div className="category-info">
            <p>• Temel zıt anlamlılar</p>
            <p>• Kelime çiftleri</p>
            <p>• İnteraktif öğrenme</p>
          </div>
        </div>
        <div className="category-card" onClick={() => handleCategoryClick('english')}>
          <h2>İngilizce Çalış</h2>
          <p>İngilizce kelime ve cümleleri öğren</p>
          <div className="category-info">
            <p>• 6. Sınıf müfredatına uygun</p>
            <p>• 4 farklı ünite</p>
            <p>• 200+ kelime ve ifade</p>
          </div>
        </div>
      </div>
      <div className="learning-tips">
        <h2>Etkili Öğrenme İpuçları</h2>
        <div className="tips-grid">
          <div className="tip-card">
            <h3>Düzenli Tekrar</h3>
            <p>Her gün 15-20 dakika çalışarak kalıcı öğrenme sağlayın.</p>
          </div>
          <div className="tip-card">
            <h3>Test Çözümü</h3>
            <p>Öğrendiklerinizi testlerle pekiştirin ve kendinizi değerlendirin.</p>
          </div>
          <div className="tip-card">
            <h3>Not Tutma</h3>
            <p>Yeni öğrendiğiniz kelime ve ifadeleri not alın.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage; 